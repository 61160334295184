import React from 'react'

function Button({texttoshow}) {
  return (
    <div >
        <button className='  py-1  bg-black px-10 myshine_gray text-[#000000] text-lg rounded-3xl text-center'>
          <h1 className=' myshine_gray'>Explore</h1>
          
        </button>
    </div>
  )
}

export default Button
